.CheckoutScreen_container {
  position: relative;
  background: lightgray;
  width: 32.5rem;
  min-height: 27.5rem;
  border-radius: 4px;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  box-shadow: 0px ​0px 11px 0 rgb(0 0 0 / 20%),
    2px 2px 16px 0 rgb(255 255 255 / 30%);
  background: #fff;
  border-radius: 4px;
}

.close_checkout {
  position: absolute;
  right: -4px;
  cursor: pointer;
  top: -4px;
}

.CheckoutScreen_container .logo {
  width: 3rem;
  height: 3rem;
  position: absolute;
  left: 22px;
  top: 8px;
}

.heading.MuiTypography-root {
  font-size: 18px;
  margin: 1rem 0;
  font-weight: 800;
}

.checkout_container_2_inner_1 {
  box-shadow: 0px ​0px 11px 0 rgb(0 0 0 / 20%),
    2px 2px 16px 0 rgb(255 255 255 / 30%);
  border-radius: 4px;
  background-color: #fff;
}

.checkout_container_2 {
  min-height: 10rem;
  width: 95%;
  margin: auto;
  margin-top: 1rem;
  font-size: 14px;
  font-weight: 600;
  color: grey;
}
.checkout_container_2_div_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid lightgray;
}
.checkout_container_2_div_1_inner_1 {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  margin-top: 0.5rem;
}

.checkout_container_2_div_1_inner_1 > p {
  /* position: absolute;
  right: 1rem;   */
}

.checkout_container_item_price {
  text-align: right;
  font-size: 1rem;
  font-weight: bold;
}

.checkout_container_format {
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

.checkout_type_icons {
  width: 4rem;
  height: 4rem;
}
.checkout_type_icons img {
  width: 100%;
}

.checkout_type_icons svg {
  width: 100%;
  height: 100%;
}

.checkout_container_2_div_2 {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0rem;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
}
.checkout_container_2_div_2 div div {
  display: flex;
  justify-content: space-between;
}
.checkout_container_2_div_2 div div p:nth-child(2) {
  margin-left: 1rem;
}
.buy_btn {
  background-color: #3b80ee;
  color: #fff;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 0.8rem;
  width: 6rem;
  height: 2rem;
  font-weight: 800;
  margin: 0.3rem;
}

.buy_btn_disabled{
  background-color: #dee2e7e9;
  color: #fff;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 0.8rem;
  width: 6rem;
  height: 2rem;
  font-weight: 800;
  margin: 0.3rem;
  cursor: none;
}

.paymentImg {
  width: 2rem;
  height: 2rem;
}
.astropay_payment_methods {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}
.astropay_payment_methods div {
  margin: 0.5rem;
}
.astropay_payment_methods img {
  height: 4rem;
  cursor: pointer;
}
@media (max-width: 530px) {
  .CheckoutScreen_container {
    width: 90%;
  }
}

.checkout-container.MuiContainer-root {
  padding: 1rem 0;
}

.checkout-section.MuiContainer-root {
  border-bottom: 2px solid #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  min-height: 18vh;
  padding: 1.5rem;
}

.checkout-cart-item {
  display: flex;
  justify-content: space-between;
  padding: 0.7rem;
  border-bottom: 1px solid #e9e9e9;
}

.checkout-close-button {
  position: absolute !important;
  right: 0;
}

.dashed-line {
  width: 40%;
  border-bottom: 1px dashed;
  margin: auto;
}

.payment-method {
  margin-bottom: 0.5rem;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
}
.payment-method .MuiPaper-elevation1 {
  box-shadow: none;
}

.cart-summary-sub-text {
  font-size: 0.7rem;
}
@font-face {
  font-family: "FairSansTextW00-Semi-Bold";
  src: url("http://db.onlinewebfonts.com/t/600f99c6bf88f60994736f1333c49119.eot"); /* IE9*/
  src: url("http://db.onlinewebfonts.com/t/600f99c6bf88f60994736f1333c49119.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("http://db.onlinewebfonts.com/t/600f99c6bf88f60994736f1333c49119.woff2")
      format("woff2"),
    /* chrome firefox */
      url("http://db.onlinewebfonts.com/t/600f99c6bf88f60994736f1333c49119.woff")
      format("woff"),
    /* chrome firefox */
      url("http://db.onlinewebfonts.com/t/600f99c6bf88f60994736f1333c49119.ttf")
      format("truetype"),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
      url("http://db.onlinewebfonts.com/t/600f99c6bf88f60994736f1333c49119.svg#FairSansTextW00-Semi-Bold")
      format("svg"); /* iOS 4.1- */
}

.loader-container {
  font-size: 3rem;
  display: flex;
}

.loader-container > p {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 1.5s linear 1;
  font-weight: 900;
  color: #2148c0;
  font-family: "FairSansTextW00-Semi-Bold" !important;
  font-style: italic;
}

@-moz-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.login_register_form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  height: 100vh;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  /* width: 100vw; */
}
.login_register_logo {
  margin-top: 2rem;
}
.input_boxes_login_register { 
  padding: 0.5rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  text-align: center;
  width: 95%;
  /* New changes */
  border: 1px solid #2148c0;
  margin-top: 1rem;
}
.input_boxes_login_register input {
  background: transparent;
  border: none;
  outline: none;
  width: 85%;
  /* new change */
  color: #2148c0;
  text-align: left;
  font-size: 1.2rem;
}
.input_boxes_login_register input::placeholder {
  /* New change */
  color: #2148c0;
}

/* input_boxes_login_register input:active .input_boxes_login_register {
  border: 1px solid #2148c0;
} */

.login_register_input_container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 2rem;
  background: #fff;
  border-radius: 5px;
  align-items: flex-start;
  margin-right: 6.25rem;
  width: 27rem;
  opacity: 0.8;
  justify-content: center;
  padding: 1.5rem;
  align-self: flex-end;
  align-items: center;
  margin: auto 0;
  margin-right: 2rem;
}
.login_register_btn.MuiButton-root {
  background-color: #704dee !important;
  color: #fff;
  border-radius: 50px;
  padding: 0.7rem 0;
  text-transform: capitalize;
  width: 100%;
  margin-top: 1rem;
}
.google_btn_container {
  border-radius: 5px;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  /* New change */
  width: 95%;
}
.google_sign_in_btn {
  display: flex;
  justify-content: center;
  outline: none;
  border: 2px solid rgb(239, 239, 239);
  background: #ffffff;
  align-items: center;
  padding: 0.3rem;
  font-size: 1rem;
  border-radius: 3px;
  cursor: pointer;
  color: #2148c0;
  width: 100%;
}
.login_register_input_container p {
  text-align: right;
  /* margin: 0.5rem; */
  /* color: white; */
}
.margin-forget-password {
  margin-top: 2rem;
}
.login_logo {
  /* width: 8rem;
  height: 8rem; */
  /* margin-top: 1rem; */
  display: flex;
  justify-content: center;
  /* New changes */
  width: 5rem;
  height: 5rem;
}
.login_logo img {
  width: 8rem;
  height: 4rem;
}
.in-forget {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem;
  padding: 0 0.6rem;
}

.forgot_password_text {
  font-size: 0.9rem;
}
@media screen and (max-width: 768px) {
  .login_register_input_container {
    margin-right: 0;
  }
}
@media screen and (max-width: 460px) {
  .login_register_input_container {
    width: 90%;
  }
  .forgot_password_text {
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 370px) {
  /* .login_register_input_container {
    width: 19rem;
  } */
  .in-forget {
    display: block;
  }
  .in-forget p {
    display: flex;
    justify-content: center;
  }
}
/* for the phone number library */
.PhoneInputCountry {
  margin-left: 13px;
}
.PhoneInput {
  width: 100%;
}

.forget-password-title.MuiTypography-root {
  font-size: 16px;
  font-weight: 700;
}

.forget-password-subtitle.MuiTypography-root {
  font-size: 14px;
}

.forget-form {
  height: min-content;
}



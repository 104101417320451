.Home_first_section {
  background: linear-gradient(180deg, rgba(227, 224, 237, 0.69) 0%, #ffffff 100%);
  min-height: 48.938rem;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  z-index: 99;
  padding-bottom: 2rem;
}

#tsparticles {
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  /* z-index: -1; */
}

.Home_first_section_Nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
  z-index: 1;
}
/* .Home_first_section_Nav img {
  max-height: 5rem;
  width: 10rem;
} */
.Home_first_section_main {
  z-index: 1;
}
.logo_container {
  display: flex;
  align-items: center;
}
.logo_container p {
  font-size: 2rem;
}
.logo_container img {
  width: 10rem;
}
.nav_link {
  display: flex;
  justify-content: center;
  align-self: center;
  align-items: center;
  height: 2.5rem;
  width: 6rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  border: none;
}
.Home_first_section_main h1 {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  line-height: 3rem;
  margin-top: 5rem;
}
.Home_first_section_main p {
  padding-top: 1rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.9rem;
}
.Home_first_section_main button {
  width: 13rem;
  height: 3rem;
  border-radius: 3.75rem;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.313rem;
  cursor: pointer;
  margin: 2rem 0;
}
.inssa-btn {
  position: relative;
}

.inssa-btn:hover {
  /* background: rgb(41 33 192) !important; */
  background: rgb(41 33 192);
}
.inssa-btn:active {
  top: 2px;
}
.inssa-btn:before {
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  line-height: 3;
  font-size: 140%;
  width: 60px;
}
.home_img {
  align-self: center;
  width: 100%;
  object-fit: scale-down;
  max-height: 35rem;
}
.search_bar_top_text {
  font-style: normal;
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
}
.username_search_container {
  display: flex;
  justify-content: center;
  width: 90%;
  align-items: center;
  align-self: center;
}
.username_search {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 1.5rem;
  padding: 0.5rem;
  border-radius: 50px;
}
.username_search img {
  margin: 0rem 1rem;
  height: 2.5rem;
  width: 2.5rem;
}
.username_search input {
  outline: none;
  border: none;
  font-size: 1.5rem;
  max-width: 8rem;
}
.mobile_container_home {
  display: flex;
  justify-content: center;
  margin: 2rem 0rem;
  align-items: center;
}
.logo_container p,
a {
  text-decoration: none;
  color: black;
}
@media (max-width: 620px) {
  .username_search {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    font-size: 1rem;
    padding: 0.3rem;
    min-width: 1rem;
  }
  .username_search img {
    margin: 0rem 1rem;
    height: 1.5rem;
    width: 1.5rem;
  }
  .username_search input {
    outline: none;
    border: none;
    min-width: 1rem;
    font-size: 0.8rem;
  }
  .username_search input::placeholder {
    font-size: 1rem;
  }
  .Home_first_section_main h1 {
    font-size: 2.5rem;
  }
  .Home_first_section_main p {
    font-size: 1.2rem;
    padding-top: 1rem;
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.rotating {
  animation: rotating 3s linear infinite;
}

@media (max-width: 500px) {
  .logo_container p {
    font-size: 1.2rem;
  }
}

.welcome-screen {
  height: 64.5vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.welcome-screen-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-section-content {
  flex: 0.45;
}
.header-section-img {
  flex: 0.45;
}
.header-section-img img {
  width: 100%;
}
/* .header-section {
  object-fit: contain;
  position: relative;
}
.header-section img {
  width: 100%;
}
.header-section-content {
  position: absolute;
  top: 20%;
  left: 5%;
} */

.header-section-contents h1 {
  font-size: 5rem;
}
.header-section-contents h2 {
  font-size: 4rem;
}
/* .header-section-contents h1,
h2 {
  font-weight: 700;
  color: #e5f62c;
  font-family: "Tangerine", cursive;
} */
.header-section-contents p {
  padding-top: 1rem;
  width: 30rem;
  color: #fff;
}
.welcome-screen-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
/* .portfolio-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */
.portfolio-link {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* width: 30%; */
}
.welcome-screen-content p {
  font-size: 0.7rem;
  color: #707c97;
}
.header-section-contents {
  flex: 0.45;
}
.header-section-img {
  flex: 0.45;
}
.header-section-img img {
  width: 100%;
}
.portfolio-link label {
  font-size: 0.7rem;
  color: #707c97;
}
/* .portfolio-link input {
  padding: 0.5rem 0.5rem;
  width: 100%;
  border-radius: 0.3rem;
  outline: none;
  border: 3px solid #70c217;
} */
.portfolio-link-green {
  text-align: center;
  padding: 0.5rem 0.5rem;
  width: 100%;
  border-radius: 0.3rem;
  outline: none;
  border: 3px solid #70c217;
}

.portfolio-link a {
  text-decoration: none;
  color: #707c97;
}
.portfolio-link a:hover {
  color: #2148c0;
}

.social {
  display: flex;
  justify-content: center;
  padding: 1rem;
  flex-direction: column;
  text-align: center;
}
.social-icons-container {
  display: flex;
  justify-content: center;
}
.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 2px solid #2148c0;
  margin: 0.5rem;
  padding: 1rem;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
}
.social-icons .MuiSvgIcon-root {
  font-size: 1.3rem;
  color: #2148c0;
  /* width: 2.3rem;
  margin: 0.1rem 0.1rem; */
}
.dashboard-button {
  text-align: center;
  padding: 0.5rem 3rem;
  /* border-radius: 10rem; */
  background: #2148c0;
  color: #fff;
  outline: none;
  border: none;
}
.refer-content {
  padding-top: 2rem;
  width: 80%;
  margin: auto;
}
.refer-content .MuiSvgIcon-root {
  color: #2148c0;
}
@media (max-width: 1100px) {
  .welcome-screen-content {
    padding-top: 2rem;
  }
  .welcome-screen {
    padding: 0;
    height: auto;
  }
  .header-section-contents p {
    width: 20rem;
  }
}
@media (max-width: 991px) {
  .welcome-screen-content {
    flex-direction: column;
  }
  .portfolio-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* width: fit-content; */
    margin: auto;
    padding: 1rem;
  }
  .header-section-contents h1 {
    font-size: 4rem;
  }
  .header-section-contents h2 {
    font-size: 3rem;
  }
  .header-section-contents p {
    font-size: 0.8rem;
  }
  /* .portfolio-link {
    width: 80%;
  }
  .social {
    width: 80%;
  }
  .refer-content {
    width: 80%;
  } */
}
@media (max-width: 768px) {
  .welcome-screen-container {
    flex-direction: column;
  }
  .header-section-contents p {
    font-size: 0.7rem;
    width: unset;
  }
}
@media (max-width: 350px) {
  .header-section-contents p {
    width: 100%;
    font-size: 0.7rem;
  }
  .header-section-contents p {
    font-size: 0.7rem;
  }
}

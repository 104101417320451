.signin-input {
  border: 1px solid #000;
  background: rgb(245, 245, 245);
  border-radius: 5px;
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  outline: none;
  resize: none;
}
.feed_modal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fefefe;
  padding: 1rem;
  border-radius: 5px;
  width: 40%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.feed_modal p {
  font-size: 1.15rem;
}
.feed_modal div {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 1rem;
}
.singin-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 29px;
  background: #7845f7;
  color: #fff;
  width: 100%;
  padding: 0.7rem 1rem;
  margin-top: 0.5rem;
}

.signin_google_icon {
  display: flex;
  position: absolute;
  left: 15px;
}
.marginTop {
  margin-top: 0.5rem;
}
.modal-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.recover_passowrd {
  display: block;
  font-size: 14px;
  font-weight: 500;
}
/* .singin-input:focus-visible {
    outline: none !important;
    border: none;
} */
.signin-card__text {
  font-size: 0.8rem;
  text-align: center;
  margin-top: 0.5rem;
}
.payment-card__payment {
  font-size: 0.7rem;
  color: rgb(33, 72, 192);
}
.payment-card__usertext {
  font-size: 1rem;
  font-weight: 300;
}

/* The Modal (background) */
.modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1200; /* Sit on top */
  left: 0;
  top: 0;
  /* transform: translate(0, -50%); */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  /* for setting up in the center */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  padding: 0.5rem;
  border-radius: 5px;
  width: 30%;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* The Close Button */
.close {
  color: #aaaaaa;
  /* float: right; */
  font-size: 1.5rem;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .modal-content {
    width: 40%;
  }
}

@media (max-width: 767px) {
  .modal-content {
    width: 60%;
  }
}

@media (max-width: 500px) {
  .modal-content {
    width: 75%;
  }
  .feed_modal {
    width: 80%;
  }
}
@media (max-width: 400px) {
  .modal-content {
    width: 90%;
  }
}

.signin-content {
  padding: 1.8rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 30rem;
}

.signin-content button {
  padding: 0.7rem 0;
}

.profile-heading.MuiTypography-root {
  font-size: 1.6rem;
  color: #704dee;
  font-weight: bolder;
}

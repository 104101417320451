* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Segoe UI", sans-serif !important;
}

:root {
  --heading: 2rem;
  --subheading: 1rem;
}

#fc_frame {
  bottom: 62px !important;
}

a,
button {
  cursor: pointer;
}

/* p {
  letter-spacing: 0.1rem;
} */
/* input[type="button"] {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
} */

input::placeholder {
  opacity: 0.7;
  font-size: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.hidden-scrollbar {
  overflow: auto;
}

.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

body {
  background-color: #e5e5e5;
}

.contact-us-container.MuiContainer-root {
  background-image: url("https://influencer-upload-files.s3.amazonaws.com/posts/_1656313318488MicrosoftTeams-image%20%287%29.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left center;
  min-height: 100vh;
}
.contact-us-content.MuiContainer-root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
}
.contact-us-form {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  border-radius: 1rem;
  padding: 2rem;
  width: 33vw;
  background-color: #fff;
  opacity: 0.9;
}

.contact-us-form .MuiTypography-root,
.contact-us-form a {
  font-size: 1.2rem;
  color: #2148c0;
  font-weight: 600;
  margin: 0 auto;
}

.contact-us-form a {
  font-size: 1rem;
}

.contact-us-form .MuiButton-root,
.contact-us-container .MuiButton-root:hover {
  width: 100%;
  height: 2rem;
  color: #fff;
  background: #2148c0;
  margin-left: auto;
}

.contact-us-container .logo {
  width: 8rem;
  margin-top: 1.5rem;
}

.contact-us-container .footer_container_1 {
  width: 100%;
  margin: 0;
}
.contact-us-header.MuiContainer-root {
  padding: 0;
}

.contact-us-form input,
.contact-us-form textarea {
  color: #2148c0;
  padding: 0.5rem;
  border: 1px solid #2148c0;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  width: 100%;
}

.contact-us-form textarea {
  resize: none;
}

.contact-us-form input::placeholder,
.contact-us-form textarea::placeholder {
  color: #2148c0;
}

.contact-us-header .MuiTypography-root {
  color: #2148c0;
  font-size: 1rem;
  font-weight: 600;
}

.contact-us-header .company-title {
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.contact-us-header a:hover {
  font-weight: 700;
}

.address.MuiTypography-root {
  font-size: 0.6rem;
  font-weight: 500;
  text-align: center;
}

.contact-us-container.MuiContainer-maxWidthLg {
  max-width: unset;
}

@media screen and (max-width: 920px) {
  .contact-us-form {
    width: 100%;
    gap: 1rem;
    margin: auto;
    margin-top: 3rem;
  }
  .contact-us-form {
    align-self: center;
  }
}

/* old footer */
/* .footer {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 20.625rem;
}
.footer_container_1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 25%;
  padding: 1rem;
}
.footer_logo {
  display: flex;
  align-items: center;
}
.footer_tagline {
  width: 90%;
}
.footer_icons {
  display: inline-block;
}
.footer_icons img {
  padding: 1rem;
}
@media (max-width: 905px) {
  .footer {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }
  .footer_container_1 {
    justify-content: center;
    width: 100%;
  }
  .footer_container_2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    width: 100%;
  }
} */
/* new footer */
footer {
  display: flex;
  justify-content: center;
  /* margin-bottom: 2rem; */
}
.footer_container_1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  min-height: 6rem;
  margin: 1rem;
  border-radius: 24px;
}
.footer_links {
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1rem;
  font-size: 1.1rem;
}
.footer_icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
